import axios from '@global/axios';
import { apiUrl, ToastMessage } from '@app/constants';
import createFetchTypes from '@app/utils/createFetchTypes';
import { createContactAttributeType } from './types';
import { ContactAttributeType } from '@app/types/modules/contact';
import { FilterOptions } from '@app/types';

const mainType = 'contactAttributes';
const ROUTE = 'contactAttributes';

export const getContactAttributesTypes = createFetchTypes(
	`${mainType}_GET_CONTACT_ATTRIBUTES`,
);

export const getAttributeValuesTypes = createFetchTypes(
	`${mainType}_GET_ATTRIBUTE_VALUES`,
);

export const createContactAttributeTypes = createFetchTypes(
	`${mainType}_CREATE_CONTACT_ATTRIBUTE`,
);

export const editContactAttributeTypes = createFetchTypes(
	`${mainType}_EDIT_CONTACT_ATTRIBUTE`,
);

export const bulkUpdateContactAttributesTypes = createFetchTypes(
	`${mainType}_BULK_UPDATE_CONTACT_ATTRIBUTES`,
);

function getContactAttributes() {
	return {
		type: getContactAttributesTypes,
		callAPI: () => axios.get(`${apiUrl}/${ROUTE}`),
		payload: {
			custom: '',
		},
	};
}

// ATTRIBUTE VALUES
function getAttributeValues(filter: FilterOptions) {
	const filterString = JSON.stringify(filter);
	return {
		type: getAttributeValuesTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/contactAttributeValue${!!filter ? `?filter=${filterString}` : ''}`,
			),
		payload: {
			custom: 'getAttributeValues',
		},
	};
}

function createContactAttribute(data: createContactAttributeType) {
	return {
		type: createContactAttributeTypes,
		callAPI: () => axios.post(`${apiUrl}/${ROUTE}`, data),
		payload: {
			custom: 'createContactAttribute',
			errorMessage: ToastMessage.error,
		},
	};
}

function editContactAttribute(
	id: string,
	data: { contactAttribute: createContactAttributeType },
) {
	return {
		type: editContactAttributeTypes,
		callAPI: () => axios.put(`${apiUrl}/${ROUTE}/${id}`, data),
		payload: {
			custom: 'editContactAttribute',
			errorMessage: ToastMessage.error,
		},
	};
}

function bulkUpdateContactAttributes(
	contactAttributes: ContactAttributeType[],
	successMessage?: string,
	errorMessage?: string,
) {
	return {
		type: bulkUpdateContactAttributesTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/contactAttributes/bulk`, { contactAttributes }),
		payload: {
			custom: 'bulkUpdateContactAttributes',
			successMessage: successMessage || '',
			errorMessage: errorMessage || ToastMessage.error,
		},
	};
}

export {
	getContactAttributes,
	getAttributeValues,
	createContactAttribute,
	editContactAttribute,
	bulkUpdateContactAttributes,
};

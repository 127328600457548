import { GenericEntityState, ResponseAction } from '@app/redux/types';
import createFetchReducer from '@app/utils/createFetchReducer';
import {
	getTeamConversationsType,
	getActiveConversationInfoType,
	updateConversationType,
	toggleStarredConversationType,
	getActiveConversationMessagesType,
	archiveConversationType,
	sendMessageToContactType,
	sendBulkMessagesType,
	getMessagesByCampaignIdWithMetricsType,
	sendMessagePreviewType,
	getSpamScoreType,
} from '@app/redux/inbox/actions';
import { createSlice } from '@reduxjs/toolkit';
import { ConversationsType } from '@app/types/modules/conversations';

import { inboxPresenceChannel } from '@app/services/Pusher/usePusher';
import { InboxPresenceEvents } from '@app/services/Pusher/events';
import moveToTopById from '@app/utils/modules/moveToTopById';
import { normalize } from 'normalizr';
import entityNormalizer from '@app/utils/entityNormalizer';
import { showToast } from '@common/design-system/components/molecules';
import { ToastMessage } from '@app/constants';

const initialState: GenericEntityState = {
	loading: false,
	error: {},
	response: {
		status: null,
		message: null,
	},

	teamConversations: {
		teamConversationsList: [],
		count: 0,
		page: 0,
		totalPages: 0,
	},
	activeConversationData: {
		conversationData: {},
		conversationSiblings: [],
		messagesData: {},
		conversationFlags: {
			isTextFieldDirty: false,
			isInvalidConversation: false,
		},
	},
	realTime: {
		conversationsWithTyping: {
			// [conversationId: string]: []
		},
	},
	messagesPerCampaignPagination: {
		pages: {},
		currentPage: 0,
		totalPages: 0,
		count: 0,
	},
	custom: {
		getTeamsConversationsLoading: false,
		getActiveConversationInfoLoading: false,
		archiveConversationLoading: false,
		getMessagesByCampaignIdWithMetricsLoading: false,
		activeConversationLoading: false,
	},
};

export type inboxStateType = typeof initialState;

function normalizeResponseConversationPerCampaign(action: ResponseAction) {
	const message = entityNormalizer('messages');
	const pagination = { messages: [message] };
	const { count, messages, page, pageSize, totalPages } = action?.response;

	return normalize({ messages, page, pageSize, totalPages, count }, pagination);
}

function getTeamConversationsMapper(
	state: inboxStateType,
	action: ResponseAction,
) {
	const {
		response: { conversations, page = null, totalPages = null, count = null },
	} = action;

	if (page > 1) {
		state.teamConversations.teamConversationsList = [
			...state.teamConversations.teamConversationsList,
			...conversations,
		];
	} else {
		state.teamConversations.teamConversationsList = conversations;
	}

	state.teamConversations.count = count;
	state.teamConversations.page = page;
	state.teamConversations.totalPages = totalPages;
}

function getActiveConversationInfoMapper(
	state: inboxStateType,
	action: ResponseAction,
) {
	//TODO: Check on the best way to normalize this data for usage

	state.activeConversationData.conversationData = action?.response.conversation;
	state.activeConversationData.conversationSiblings = action?.response.siblings;
}

function getActiveConversationErrorMapper(state: inboxStateType) {
	state.activeConversationData.conversationFlags.isInvalidConversation = true;
}

function getActiveConversationMessagesMapper(
	state: inboxStateType,
	action: ResponseAction,
) {
	const {
		response: { messages, page = null, totalPages = null, count = null },
	} = action;

	if (page > 1) {
		state.activeConversationData.messagesData = {
			...state.activeConversationData.messagesData,
			messages: [
				...state.activeConversationData.messagesData.messages,
				...messages,
			],
			page,
			totalPages,
			count,
		};
	} else {
		state.activeConversationData.messagesData = {
			...state.activeConversationData.messagesData,
			messages,
			page,
			totalPages,
			count,
		};
	}
}

function updateConversationMapper(
	state: inboxStateType,
	action: ResponseAction,
) {
	const conversationUpdated = action?.response?.conversation;

	const conversationIndex =
		state.teamConversations.teamConversationsList.findIndex(
			(conversation: ConversationsType) =>
				conversation._id === conversationUpdated?._id,
		);

	//Update active conversation if this is the one updated
	if (
		state.activeConversationData.conversationData?.id ===
		conversationUpdated?._id
	) {
		state.activeConversationData = {
			...state.activeConversationData,
			conversationData: {
				...state.activeConversationData.conversationData,
				...conversationUpdated,
				contact: state.activeConversationData.conversationData.contact,
			},
		};
	}

	//Update conversation in team conversations list
	if (conversationIndex !== -1) {
		state.teamConversations = {
			...state.teamConversations,
			teamConversationsList: state.teamConversations.teamConversationsList.map(
				(conversation: ConversationsType, index: number) =>
					index === conversationIndex
						? {
								...conversation,
								...conversationUpdated,
								contact: conversation.contact,
							}
						: conversation,
			),
		};
	}
}

function toggleStarredConversationMapper(
	state: inboxStateType,
	action: ResponseAction,
) {
	const conversationUpdated = action?.response?.conversation;

	const conversationIndex =
		state.teamConversations.teamConversationsList.findIndex(
			(conversation: ConversationsType) =>
				conversation._id === conversationUpdated._id,
		);

	if (
		state.activeConversationData.conversationData?.id ===
		conversationUpdated._id
	) {
		state.activeConversationData.conversationData = {
			...state.activeConversationData.conversationData,
			starred: conversationUpdated.starred,
		};
	}

	state.teamConversations.teamConversationsList[conversationIndex] = {
		...state.teamConversations.teamConversationsList[conversationIndex],
		starred: conversationUpdated.starred,
	};

	//TODO: Evaluate migration to useRealtimeInbox
	inboxPresenceChannel?.trigger(
		InboxPresenceEvents.TOGGLE_STARRED_CONVERSATION,
		{
			conversationId: conversationUpdated._id,
			starred: conversationUpdated.starred,
		},
	);
}

const getMessagesByCampaignIdWithMetricsMapper = (
	state: inboxStateType,
	action: ResponseAction,
) => {
	const normalizedConversations =
		normalizeResponseConversationPerCampaign(action);

	if (state.messagesPerCampaignPagination.pages) {
		state.messagesPerCampaignPagination.pages[action?.response?.page] =
			normalizedConversations.result.messages;
	}
	state.messagesPerCampaignPagination.currentPage = action?.response?.page;
	state.messagesPerCampaignPagination.totalPages = action?.response?.totalPages;
	state.messagesPerCampaignPagination.count = action?.response?.count;
};

const archivedConversationMapper = (
	state: inboxStateType,
	action: ResponseAction,
) => {
	const conversationUpdated = action?.response?.conversation;

	//TODO: Evaluate migration to useRealtimeInbox
	inboxPresenceChannel?.trigger(
		InboxPresenceEvents.TOGGLE_ARCHIVED_CONVERSATION,
		{
			conversationId: conversationUpdated._id,
			archived: conversationUpdated.archived,
			conversation: conversationUpdated,
		},
	);

	/** Update active conversation if this is the one updated **/
	if (
		state.activeConversationData.conversationData?.id ===
		conversationUpdated?._id
	) {
		state.activeConversationData = {
			...state.activeConversationData,
			conversationData: {
				...state.activeConversationData.conversationData,
				archived: conversationUpdated.archived,
			},
		};
	}
};

const sendBulkMessagesErrorMapper = (
	state: inboxStateType,
	action: ResponseAction,
) => {
	const {
		error: { response },
	} = action;

	const errorCode = response?.data?.errorCode;
	const contactsAreInvalid = errorCode === 304;

	if (contactsAreInvalid) {
		return showToast({
			type: 'error',
			message: ToastMessage.contactsAreInvalid,
		});
	}

	showToast({
		type: 'error',
		message: ToastMessage.messagesSentError,
	});
};

const inboxSlice = createSlice({
	name: 'inbox',
	initialState,
	reducers: {
		RESET_TEAM_CONVERSATIONS: (state) => {
			state.teamConversations = {
				teamConversationsList: [],
				count: 0,
				page: 0,
				totalPages: 0,
			};
		},
		RESET_CONVERSATION_DATA: (state) => {
			state.activeConversationData = {
				conversationData: {},
				messagesData: {},
				conversationSiblings: [],
				conversationFlags: {
					isTextFieldDirty: false,
					isInvalidConversation: false,
				},
			};
		},
		REMOVE_CONVERSATION_FROM_LIST: (state, action) => {
			const conversationId = action.payload.conversationId;

			state.teamConversations.teamConversationsList =
				state.teamConversations.teamConversationsList.filter(
					(conversation: ConversationsType) =>
						conversation._id !== conversationId,
				);
			state.teamConversations.count = state.teamConversations.count - 1;
		},
		ADD_CONVERSATION_TO_LIST: (state, action) => {
			const conversation = action.payload.conversation;

			state.teamConversations.teamConversationsList = [
				conversation,
				...state.teamConversations.teamConversationsList,
			];
			state.teamConversations.count = state.teamConversations.count + 1;
		},
		UPDATE_INACTIVE_STATE_IN_LIST: (state, action) => {
			const conversationId = action.payload.conversationId;
			const inactive = action.payload.inactive;

			const conversationIndex =
				state.teamConversations.teamConversationsList.findIndex(
					(conversation: ConversationsType) =>
						conversation._id === conversationId,
				);

			if (conversationIndex !== -1) {
				state.teamConversations.teamConversationsList[conversationIndex] = {
					...state.teamConversations.teamConversationsList[conversationIndex],
					inactive,
				};
			}
		},
		UPDATE_ARCHIVED_STATE_IN_ACTIVE_CONVERSATION: (state, action) => {
			const conversationId = action.payload.conversationId;
			const archived = action.payload.archived;

			if (
				state.activeConversationData.conversationData?.id === conversationId
			) {
				state.activeConversationData.conversationData = {
					...state.activeConversationData.conversationData,
					archived,
				};
			}
		},
		UPDATE_OPT_OUT_STATE_IN_LIST: (state, action) => {
			const conversationId = action.payload.conversationId;
			const isUnsubscribed = action.payload.isUnsubscribed;

			const conversationIndex =
				state.teamConversations.teamConversationsList.findIndex(
					(conversation: ConversationsType) =>
						conversation._id === conversationId,
				);

			if (conversationIndex !== -1) {
				state.teamConversations.teamConversationsList[conversationIndex] = {
					...state.teamConversations.teamConversationsList[conversationIndex],
					contact: {
						...state.teamConversations.teamConversationsList[conversationIndex]
							.contact,
						unsubscribed: isUnsubscribed,
					},
				};
			}
		},
		UPDATE_OPT_OUT_STATE_IN_ACTIVE_CONVERSATION: (state, action) => {
			const conversationId = action.payload.conversationId;
			const isUnsubscribed = action.payload.isUnsubscribed;
			if (
				state.activeConversationData.conversationData?.id === conversationId
			) {
				state.activeConversationData.conversationData = {
					...state.activeConversationData.conversationData,
					contact: {
						...state.activeConversationData.conversationData.contact,
						unsubscribed: isUnsubscribed,
					},
				};
			}
		},
		UPDATE_CREATED_CONTACT_NAME_IN_LIST: (state, action) => {
			const conversationId = action.payload.conversationId;
			const firstName = action.payload.firstName;
			const lastName = action.payload.lastName;

			const conversationIndex =
				state.teamConversations.teamConversationsList.findIndex(
					(conversation: ConversationsType) =>
						conversation._id === conversationId,
				);

			if (conversationIndex !== -1) {
				state.teamConversations.teamConversationsList[conversationIndex] = {
					...state.teamConversations.teamConversationsList[conversationIndex],
					contact: {
						...state.teamConversations.teamConversationsList[conversationIndex]
							.contact,
						firstName,
						lastName,
						fullName: `${firstName} ${lastName}`,
					},
				};
			}
		},
		UPDATE_CREATED_CONTACT_NAME_IN_ACTIVE_CONVERSATION: (state, action) => {
			const conversationId = action.payload.conversationId;
			const firstName = action.payload.firstName;
			const lastName = action.payload.lastName;
			if (
				state.activeConversationData.conversationData?.id === conversationId
			) {
				state.activeConversationData.conversationData = {
					...state.activeConversationData.conversationData,
					contact: {
						...state.activeConversationData.conversationData.contact,
						firstName,
						lastName,
						fullName: `${firstName} ${lastName}`,
					},
				};

				// Change the new contact name in the messages description
				const messagesData = state.activeConversationData.messagesData;
				const updatedMessages = messagesData?.messages?.map((message: any) => {
					const updatedContact = {
						...message?.contact,
						fullName: `${firstName} ${lastName}`,
					};
					return {
						...message,
						contact: { ...updatedContact },
					};
				});
				state.activeConversationData.messagesData = {
					...messagesData,
					messages: updatedMessages,
				};
			}
		},
		UPDATE_CONVERSATION_LAST_MESSAGE_IN_LIST: (state, action) => {
			const lastMessage = action.payload.message;
			const conversationId = action.payload.message.conversation;

			const conversationIndex =
				state.teamConversations.teamConversationsList.findIndex(
					(conversation: ConversationsType) =>
						conversation._id === conversationId,
				);

			if (conversationIndex !== -1) {
				state.teamConversations.teamConversationsList[conversationIndex] = {
					...state.teamConversations.teamConversationsList[conversationIndex],
					lastMessage,
				};
			}
		},
		UPDATE_CONVERSATION_STARRED: (state, action) => {
			const conversationId = action.payload.conversationId;
			const starred = action.payload.starred;

			//Update conversation in team conversations list
			const conversationIndex =
				state.teamConversations.teamConversationsList.findIndex(
					(conversation: ConversationsType) =>
						conversation._id === conversationId,
				);

			if (conversationIndex !== -1) {
				state.teamConversations.teamConversationsList[conversationIndex] = {
					...state.teamConversations.teamConversationsList[conversationIndex],
					starred,
				};
			}

			//Update active conversation if this is the one updated
			if (
				state.activeConversationData.conversationData?.id === conversationId
			) {
				state.activeConversationData = {
					...state.activeConversationData,
					conversationData: {
						...state.activeConversationData.conversationData,
						starred,
					},
				};
			}
		},
		UPDATE_CONVERSATION_READ: (state, action) => {
			const conversationId = action.payload.conversationId;
			const read = action.payload.read;

			//Update conversation in team conversations list
			const conversationIndex =
				state.teamConversations.teamConversationsList.findIndex(
					(conversation: ConversationsType) =>
						conversation._id === conversationId,
				);

			const isActiveConversation =
				state.activeConversationData.conversationData?.id === conversationId;

			if (conversationIndex !== -1 && !isActiveConversation) {
				state.teamConversations.teamConversationsList[conversationIndex] = {
					...state.teamConversations.teamConversationsList[conversationIndex],
					read,
				};
			}

			//Update active only in read true case

			if (isActiveConversation && read) {
				state.activeConversationData = {
					...state.activeConversationData,
					conversationData: {
						...state.activeConversationData.conversationData,
						read,
					},
				};
			}
		},
		ADD_MESSAGE_TO_ACTIVE_CONVERSATION_MESSAGES: (state, action) => {
			const message = action.payload.message;

			state.activeConversationData.messagesData = {
				...state.activeConversationData.messagesData,
				messages: [
					{
						...message,
						contact: state.activeConversationData.conversationData.contact,
					},
					...state.activeConversationData.messagesData.messages,
				],
			};
		},
		UPDATE_MESSAGE_STATUS_FOR_ACTIVE_CONVERSATION: (state, action) => {
			const message = action.payload.message;

			state.activeConversationData.messagesData = {
				...state.activeConversationData.messagesData,
				messages: state.activeConversationData?.messagesData?.messages?.map(
					(messageInList: any) =>
						messageInList._id === message._id
							? {
									...messageInList,
									status: message.status,
									content: {
										...messageInList.content,
										...(message?.content?.text
											? { text: message?.content?.text }
											: {}),
									},
								}
							: messageInList,
				),
			};
		},
		UPDATE_REALTIME_CONVERSATION_TYPING: (state, action) => {
			const { conversationId, userId, stopped } = action.payload;

			const typingConversation =
				state.realTime.conversationsWithTyping[conversationId];
			const hasConversation = !!typingConversation;

			// Insert userId in the list of users typing in the conversation

			if (
				!hasConversation ||
				(hasConversation && typingConversation.indexOf(userId) === -1)
			) {
				state.realTime.conversationsWithTyping = {
					...state.realTime.conversationsWithTyping,
					[conversationId]: [
						...(state.realTime.conversationsWithTyping[conversationId] ?? []),
						userId,
					],
				};
			}

			// Remove userId from the list of users typing in the conversation

			if (state.realTime.conversationsWithTyping[conversationId] && stopped) {
				state.realTime.conversationsWithTyping = {
					...state.realTime.conversationsWithTyping,
					[conversationId]: state.realTime.conversationsWithTyping[
						conversationId
					].filter((userIdInList: string) => userIdInList !== userId),
				};
			}
		},
		REORDER_LIST_BRING_TOP_BY_ID: (state, action) => {
			const conversationId = action.payload.conversationId;

			const reorderedArray = moveToTopById(
				conversationId,
				state.teamConversations.teamConversationsList,
			);

			state.teamConversations.teamConversationsList = reorderedArray;
		},
		UPDATE_ACTIVE_CONVERSATION_TEXT_FIELD_DIRTY: (state, action) => {
			state.activeConversationData = {
				...state.activeConversationData,
				conversationFlags: {
					...state.activeConversationData.conversationFlags,
					isTextFieldDirty: action.payload.textFieldDirty,
				},
			};
		},
	},
	extraReducers: {
		...createFetchReducer(getTeamConversationsType, getTeamConversationsMapper),
		...createFetchReducer(
			getActiveConversationInfoType,
			getActiveConversationInfoMapper,
			getActiveConversationErrorMapper,
		),
		...createFetchReducer(
			getActiveConversationMessagesType,
			getActiveConversationMessagesMapper,
		),
		...createFetchReducer(updateConversationType, updateConversationMapper),
		...createFetchReducer(
			toggleStarredConversationType,
			toggleStarredConversationMapper,
		),
		...createFetchReducer(archiveConversationType, archivedConversationMapper),
		...createFetchReducer(sendMessagePreviewType),
		...createFetchReducer(sendMessageToContactType),
		...createFetchReducer(
			sendBulkMessagesType,
			undefined,
			sendBulkMessagesErrorMapper,
		),
		...createFetchReducer(
			getMessagesByCampaignIdWithMetricsType,
			getMessagesByCampaignIdWithMetricsMapper,
		),
		...createFetchReducer(getSpamScoreType),
	},
});

export default inboxSlice.reducer;

/* eslint-disable prettier/prettier */
import { fetchAction, RootState } from '@app/redux/types';
import axios from '@global/axios';
import { apiUrl, orderTypes, ToastMessage } from '@app/constants';
import createFetchTypes from '@app/utils/createFetchTypes';
import { ConversationsType } from '@app/types/modules/conversations';
import { Dispatch } from '@reduxjs/toolkit';
import {
	getTeamConversationsServiceType,
	handleConversationArchivedInListServiceType,
	handleConversationUnsubscribedServiceType,
	InboxTab,
	PreviewSMSMessageType,
	PreviewWhatsappMessageType,
	SMSMessageType,
	WhatsAppMessageType,
} from '@app/redux/inbox/types';
import { MessageType } from '@app/containers/Inbox/components/ConversationDetails/ConversationDetails';
import { FilterOptions } from '@app/types';
import {
	updateTeamUnreadCount,
	updateTotalUnreadsCount,
} from '@app/redux/teams/action';
import { InboxPresenceEvents } from '@app/services/Pusher/events';
import { inboxPresenceChannel } from '@app/services/Pusher/usePusher';
import { audienceHandlingTypeEnum } from '@app/containers/Inbox/constants';

const mainType = 'INBOX';

export const getTeamConversationsType = createFetchTypes(
	`${mainType}_GET_TEAM_CONVERSATIONS`,
);

function getTeamConversations({
	teamId,
	filterOptions,
	tab,
}: getTeamConversationsServiceType): fetchAction {
	const filterString = JSON.stringify(filterOptions);
	const url = `${apiUrl}/conversations/latest/${teamId}?ownedBy=team${
		filterOptions ? `&filter=${filterString}` : ''
	} ${tab ? `&tab=${tab}` : ''}`;

	return {
		type: getTeamConversationsType,
		callAPI: () => axios.get(url),
		payload: {
			custom: 'getTeamsConversations',
		},
	};
}

export const getActiveConversationInfoType = createFetchTypes(
	`${mainType}_GET_ACTIVE_CONVERSATION_INFO`,
);

function getActiveConversationInfo(conversationId: string): fetchAction {
	return {
		type: getActiveConversationInfoType,
		callAPI: () => axios.get(`${apiUrl}/conversations/${conversationId}`),
		payload: {
			custom: 'getActiveConversationInfo',
		},
	};
}

export const getActiveConversationMessagesType = createFetchTypes(
	`${mainType}_GET_ACTIVE_CONVERSATION_MESSAGES`,
);

function getActiveConversationMessages(
	conversationId: string,
	paginationFilter?: FilterOptions['pagination'],
): fetchAction {
	const filterOptions = {
		filter: [{ field: 'conversation', value: conversationId }],
		pagination: paginationFilter ?? { page: 1, pageSize: 15 },
		populate: [
			{ field: 'contact', select: ['firstName', 'lastName', 'fullName'] },
			{ field: 'team', select: ['name', 'icon'] },
			{
				field: 'storyteller',
				select: ['firstName', 'lastName', 'fullName', 'profilePicture'],
			},
			{
				field: 'reply',
				select: ['message', 'sharedVideo', 'type', 'campaign'],
			},
			{
				field: 'story',
				select: ['video'],
				populate: { field: 'video', select: ['thumbnail', 'MMSThumbnail'] },
			},
			{
				field: 'campaign',
				select: ['launchedBy', 'name', 'emailSubjectTitle'],
				populate: {
					field: 'launchedBy',
					select: ['firstName', 'lastName', 'fullName'],
				},
			},
		],
		order: { field: 'createdAt', type: orderTypes.descending },
	};

	const filterString = JSON.stringify(filterOptions);

	return {
		type: getActiveConversationMessagesType,
		callAPI: () => axios.get(`${apiUrl}/messages?filter=${filterString}`),
		payload: {
			custom: 'getActiveConversationMessages',
		},
	};
}

export const updateConversationType = createFetchTypes(
	`${mainType}_UPDATE_CONVERSATION`,
);

function updateConversation(
	conversationId: string,
	data: Partial<ConversationsType>,
): fetchAction {
	return {
		type: updateConversationType,
		callAPI: () =>
			axios.patch(`${apiUrl}/conversations/${conversationId}`, data),
		payload: {
			custom: 'updateConversation',
		},
	};
}

export const sendMessagePreviewType = createFetchTypes(
	`${mainType}_SEND_MESSAGE_PREVIEW`,
);

function sendMessagePreview(
	data: PreviewSMSMessageType | PreviewWhatsappMessageType,
): fetchAction {
	return {
		type: sendMessagePreviewType,
		callAPI: () => axios.post(`${apiUrl}/messages/preview`, data),
		payload: {
			successMessage: ToastMessage.previewMessageSent,
			errorMessage: ToastMessage.error,
			custom: 'sendMessagePreview',
		},
	};
}

export const sendMessageToContactType = createFetchTypes(
	`${mainType}_SEND_MESSAGE_TO_CONTACT`,
);

function sendMessageToContact(
	data: WhatsAppMessageType | SMSMessageType,
): fetchAction {
	return {
		type: sendMessageToContactType,
		callAPI: () => axios.post(`${apiUrl}/messages/`, data),
	};
}

export const sendBulkMessagesType = createFetchTypes(
	`${mainType}_SEND_BULK_MESSAGES`,
);

export const getSpamScoreType = createFetchTypes(
	`${mainType}_GET_SPAM_SCORE_TYPE`,
);

function sendBulkMessages({
	message,
	contacts,
	teamId,
	scheduleDate,
	audience,
	trackAsCampaign,
	campaignName,
	category,
	whatsappTemplateId,
	hasMergeTags,
	medias,
	audienceHandlingType,
	campaignId,
}: {
	message?: string;
	contacts?: string[];
	teamId: string;
	scheduleDate?: string;
	audience?: string;
	trackAsCampaign?: boolean;
	campaignName?: string;
	category: string;
	whatsappTemplateId?: string;
	hasMergeTags?: boolean;
	medias?: {
		url: string;
		status: string;
		type: string;
	}[];
	audienceHandlingType?: audienceHandlingTypeEnum;
	campaignId?: string;
}): fetchAction {
	return {
		type: sendBulkMessagesType,
		callAPI: () =>
			axios.post(`${apiUrl}/messages/bulk`, {
				...(!!message && {
					content: {
						text: message,
						...(hasMergeTags ? { useMergeTags: true } : {}),
						...(medias?.length ? { medias } : {}),
					},
				}),
				...(!!contacts && { contacts: contacts }),
				...(!!audience && { audience, audienceHandlingType }),
				team: teamId,
				scheduleDate: scheduleDate,
				trackAsCampaign: trackAsCampaign,
				campaignName: campaignName,
				sendNow: !scheduleDate,
				category: category,
				...(!!whatsappTemplateId && { whatsappTemplateId: whatsappTemplateId }),
				// It's important to send the campaignId when a draft campaign is send to avoid creating a new campaign.
				...(!!campaignId && { campaignId }),
			}),
		payload: {
			successMessage: ToastMessage.messagesSent,
			custom: 'sendBulkMessages',
		},
	};
}

function clearTeamConversations() {
	return {
		type: `inbox/RESET_TEAM_CONVERSATIONS`,
	};
}

function clearActiveConversation() {
	return {
		type: `inbox/RESET_CONVERSATION_DATA`,
	};
}

function removeConversationFromList(conversationId: string) {
	return {
		type: `inbox/REMOVE_CONVERSATION_FROM_LIST`,
		payload: {
			conversationId,
		},
	};
}

function updateInactiveStatusForConversationList(
	conversation: Partial<ConversationsType>,
) {
	return {
		type: `inbox/UPDATE_INACTIVE_STATE_IN_LIST`,
		payload: {
			conversationId: conversation?._id,
			inactive: conversation?.inactive,
		},
	};
}

function updateArchivedStateInActiveConversation(
	conversation: Partial<ConversationsType>,
) {
	return {
		type: `inbox/UPDATE_ARCHIVED_STATE_IN_ACTIVE_CONVERSATION`,
		payload: {
			conversationId: conversation?._id,
			archived: conversation?.archived,
		},
	};
}

function addConversationToList(conversation: Partial<ConversationsType>) {
	return {
		type: `inbox/ADD_CONVERSATION_TO_LIST`,
		payload: {
			conversation,
		},
	};
}

function reorderListBringTopById(conversationId: string) {
	return {
		type: `inbox/REORDER_LIST_BRING_TOP_BY_ID`,
		payload: {
			conversationId,
		},
	};
}

function updateOptOutStateInConversationList(
	conversation: Partial<ConversationsType>,
) {
	return {
		type: `inbox/UPDATE_OPT_OUT_STATE_IN_LIST`,
		payload: {
			conversationId: conversation?._id,
			isUnsubscribed: conversation?.contact?.unsubscribed,
		},
	};
}

function updateOptOutStateInArchivedConversation(
	conversation: Partial<ConversationsType>,
) {
	return {
		type: `inbox/UPDATE_OPT_OUT_STATE_IN_ACTIVE_CONVERSATION`,
		payload: {
			conversationId: conversation?._id,
			isUnsubscribed: conversation?.contact?.unsubscribed,
		},
	};
}

function updateCreatedContactNameInActiveConversation(
	conversationId: string,
	firstName: string,
	lastName: string,
) {
	return {
		type: `inbox/UPDATE_CREATED_CONTACT_NAME_IN_ACTIVE_CONVERSATION`,
		payload: {
			conversationId,
			firstName,
			lastName,
		},
	};
}

function updateCreatedContactNameInConversationList(
	conversationId: string,
	firstName: string,
	lastName: string,
) {
	return {
		type: `inbox/UPDATE_CREATED_CONTACT_NAME_IN_LIST`,
		payload: {
			conversationId,
			firstName,
			lastName,
		},
	};
}

function updateActiveConversationTextFieldDirty(textFieldDirty: boolean) {
	return {
		type: `inbox/UPDATE_ACTIVE_CONVERSATION_TEXT_FIELD_DIRTY`,
		payload: {
			textFieldDirty,
		},
	};
}

function handleConversationArchiveInList({
	conversationId,
	isArchived,
	tab,
	conversation,
}: handleConversationArchivedInListServiceType) {
	return async (dispatch: Dispatch, getState: () => RootState) => {
		const { inbox } = getState();

		const conversationsList = inbox?.teamConversations?.teamConversationsList;

		const isInboxTab = tab === InboxTab.INBOX;
		const isArchivedTab = tab === InboxTab.ARCHIVED;

		const isItemListed = conversationsList?.some(
			(conversation: ConversationsType) => conversation.id === conversationId,
		);

		// Inbox -> Archived
		if (isInboxTab) {
			// Remove
			const isRemovingConversationFromList = isItemListed && isArchived;
			if (isRemovingConversationFromList) {
				dispatch(removeConversationFromList(conversationId));
			}

			// Add
			const isAddingActiveConversationToList = !isArchived && !isItemListed;
			if (isAddingActiveConversationToList) {
				dispatch(addConversationToList(conversation));
			}
		}

		// Archived -> Inbox
		if (isArchivedTab) {
			// Remove
			const isRemovingConversation = !isArchived && isItemListed;
			if (isRemovingConversation) {
				dispatch(removeConversationFromList(conversationId));
			}

			// Add
			const isAddingArchivedConversationToList = isArchived && !isItemListed;
			if (isAddingArchivedConversationToList) {
				dispatch(addConversationToList(conversation as any));
			}
		}
	};
}

function handleReadConversation({
	conversationId,
	read,
	teamId,
}: {
	conversationId: string;
	read: boolean;
	teamId?: string;
}) {
	return async (dispatch: Dispatch, getState: () => RootState) => {
		const { teams } = getState();

		if (teamId) {
			let totalUnreads = read
				? teams?.totalUnreads - 1
				: teams?.totalUnreads + 1;

			let unReadByTeam = read
				? teams.teams[teamId].unreadConversations - 1
				: teams.teams[teamId].unreadConversations + 1;

			totalUnreads = Math.sign(totalUnreads) === -1 ? 0 : totalUnreads;
			unReadByTeam = Math.sign(unReadByTeam) === -1 ? 0 : unReadByTeam;

			dispatch(updateTeamUnreadCount(teamId, unReadByTeam));
			dispatch(updateTotalUnreadsCount(totalUnreads));

			if (inboxPresenceChannel?.subscribed) {
				inboxPresenceChannel?.trigger(InboxPresenceEvents.OPEN_CONVERSATION, {
					conversationId,
					teamId,
					unReadByTeam,
					totalUnreads,
					read,
				});
			}
		}

		dispatch(updateConversation(conversationId, { read }));
	};
}

function handleNewConversationInList(conversation: Partial<ConversationsType>) {
	return async (dispatch: Dispatch, getState: () => RootState) => {
		const { inbox } = getState();

		const conversationsList = inbox?.teamConversations?.teamConversationsList;

		const isItemListed = conversationsList?.find(
			(conversationItem: ConversationsType) =>
				conversationItem?._id === conversation?._id,
		);

		if (!isItemListed) {
			dispatch(addConversationToList(conversation));
		}
	};
}

function handleConversationOptedOutInList({
	tab,
	conversation,
	conversationId,
}: handleConversationUnsubscribedServiceType) {
	return async (dispatch: Dispatch, getState: () => RootState) => {
		const { inbox } = getState();

		const conversationsList = inbox?.teamConversations?.teamConversationsList;

		const isOptOutTab = tab === InboxTab.OPTED_OUT;
		const isActiveOrInactiveTab =
			tab === InboxTab.INBOX || tab === InboxTab.ARCHIVED;

		const isItemListed = conversationsList?.some(
			(conversationItem: ConversationsType) =>
				conversationItem.id === conversationId,
		);

		// Inbox -> OptedOut or Archived -> OptedOut
		if (isActiveOrInactiveTab) {
			// Remove
			if (isItemListed) {
				dispatch(removeConversationFromList(conversationId));
			}
		}

		if (isOptOutTab) {
			if (!isItemListed) {
				dispatch(addConversationToList(conversation));
			}
		}
	};
}

function handleMessageReceived(message: MessageType) {
	return async (dispatch: Dispatch, getState: () => RootState) => {
		const { inbox, teams } = getState();

		const teamId = teams?.currentTeamInDetails?._id;

		if (teamId) {
			const totalTeamsCount = teams?.totalUnreads || 0;
			const teamCount = teams?.currentTeamInDetails?.unreadConversations || 0;
			dispatch(updateTotalUnreadsCount(totalTeamsCount + 1));
			dispatch(
				updateTeamUnreadCount(teams?.currentTeamInDetails?._id, teamCount + 1),
			);
		}

		const activeConversationId =
			inbox?.activeConversationData?.conversationData?.id;

		const conversationId = message?.conversation;

		const isMessageInList =
			inbox?.activeConversationData?.messagesData?.messages?.find(
				(messageInList: MessageType) => messageInList?._id === message?._id,
			);

		if (activeConversationId === conversationId && !isMessageInList) {
			dispatch(addMessageToActiveConversation(message));

			/*We update the conversation on db if a message is received on the conversation active by the user, 
			this is also updated when the user opens a conversation, see effects in: ConversationDetails.tsx **/
			dispatch(updateConversation(activeConversationId, { read: true }));
		}

		// If the user is in another has another conversation active, we update the conversation to read false on the global state
		if (activeConversationId !== conversationId) {
			dispatch(updateConversationRead(conversationId, false));
		}

		// We update the last message of the conversation on the list and bring the conversation to the top on global state
		dispatch(updateConversationLastMessageInList(message));
		dispatch(reorderListBringTopById(conversationId));
	};
}

function handleMessageCreated(message: MessageType) {
	return async (dispatch: Dispatch, getState: () => RootState) => {
		const { inbox } = getState();

		const activeConversationId =
			inbox?.activeConversationData?.conversationData?.id;

		const conversationId = message?.conversation;

		const isMessageInList =
			inbox?.activeConversationData?.messagesData?.messages?.find(
				(messageInList: MessageType) => messageInList?._id === message?._id,
			);

		if (activeConversationId === conversationId && !isMessageInList) {
			dispatch(addMessageToActiveConversation(message));

			/* We update the conversation on db if a message is created on the conversation active by the user, 
			this is also updated when the user opens a conversation, see effects in: ConversationDetails.tsx **/
			dispatch(updateConversation(activeConversationId, { read: true }));
		}

		// We update the last message of the conversation on the list
		dispatch(updateConversationLastMessageInList(message));
	};
}

/** This action is used to update the status, and the last message when is sent
 * for the active conversation details or the list if the item is listed.
 * **/
function handleUpdateMessageEventConversation(message: Partial<MessageType>) {
	return async (dispatch: Dispatch) => {
		const messageText = message?.content?.text;

		dispatch(updateMessageStatusForActiveConversation(message));
		if (messageText) {
			dispatch(updateConversationLastMessageInList(message));
		}
	};
}

function updateConversationStarred({
	conversationId,
	starred,
}: {
	conversationId: string;
	starred: boolean;
}) {
	return {
		type: `inbox/UPDATE_CONVERSATION_STARRED`,
		payload: {
			conversationId,
			starred,
		},
	};
}

function updateConversationRead(conversationId: string, read: boolean) {
	return {
		type: `inbox/UPDATE_CONVERSATION_READ`,
		payload: {
			conversationId,
			read,
		},
	};
}

function addMessageToActiveConversation(message: any) {
	return {
		type: `inbox/ADD_MESSAGE_TO_ACTIVE_CONVERSATION_MESSAGES`,
		payload: {
			message,
		},
	};
}

function updateConversationLastMessageInList(message: any) {
	return {
		type: `inbox/UPDATE_CONVERSATION_LAST_MESSAGE_IN_LIST`,
		payload: {
			message,
		},
	};
}

function updateMessageStatusForActiveConversation(message: any) {
	return {
		type: `inbox/UPDATE_MESSAGE_STATUS_FOR_ACTIVE_CONVERSATION`,
		payload: {
			message,
		},
	};
}

function updateRealtimeConversationTyping(
	conversationId: string,
	userId: string,
	stopped = false,
) {
	return {
		type: `inbox/UPDATE_REALTIME_CONVERSATION_TYPING`,
		payload: {
			conversationId,
			userId,
			stopped,
		},
	};
}

export const toggleStarredConversationType = createFetchTypes(
	`${mainType}_TOGGLE_STARRED_CONVERSATION`,
);

function toggleStarredConversation(conversationId: string) {
	return {
		type: toggleStarredConversationType,
		callAPI: () =>
			axios.put(`${apiUrl}/conversations/${conversationId}/toggleStarred`),
	};
}

export const archiveConversationType = createFetchTypes(
	`${mainType}_ARCHIVE_CONVERSATION`,
);
function archiveConversation(conversationId: string) {
	return {
		type: archiveConversationType,
		callAPI: () =>
			axios.put(`${apiUrl}/conversations/${conversationId}/toggleArchived`),
		payload: {
			custom: 'archiveConversation',
			errorMessage: ToastMessage.error,
		},
	};
}

export const getMessagesByCampaignIdWithMetricsType = createFetchTypes(
	`${mainType}_GET_MESSAGES_BY_CAMPAIGN_ID_WITH_METRICS`,
);

//This action is used to track the stats in the campaign report
function getMessagesByCampaignIdWithMetrics(
	campaignId: string,
	filterOptions?: FilterOptions,
) {
	const filterString = filterOptions ? JSON.stringify(filterOptions) : '';
	return {
		type: getMessagesByCampaignIdWithMetricsType,
		callAPI: () =>
			axios.get(
				`${apiUrl}/messages/campaign/${campaignId}${
					filterString ? `?filter=${filterString}` : ''
				}`,
			),
		payload: {
			custom: 'getMessagesByCampaignIdWithMetrics',
		},
	};
}

function getSpamScore(message?: string) {
	return {
		type: getSpamScoreType,
		callAPI: () => axios.post(`${apiUrl}/copilot/getSpamScore`, { message }),
		payload: {
			custom: 'getSpamScore',
		},
	};
}

export {
	getTeamConversations,
	getActiveConversationInfo,
	getActiveConversationMessages,
	updateConversation,
	clearTeamConversations,
	toggleStarredConversation,
	archiveConversation,
	sendMessagePreview,
	sendMessageToContact,
	sendBulkMessages,
	clearActiveConversation,
	removeConversationFromList,
	reorderListBringTopById,
	addConversationToList,
	updateConversationStarred,
	updateConversationRead,
	addMessageToActiveConversation,
	updateConversationLastMessageInList,
	updateMessageStatusForActiveConversation,
	handleConversationArchiveInList,
	updateRealtimeConversationTyping,
	handleConversationOptedOutInList,
	handleMessageReceived,
	handleMessageCreated,
	updateInactiveStatusForConversationList,
	updateOptOutStateInArchivedConversation,
	updateCreatedContactNameInConversationList,
	updateCreatedContactNameInActiveConversation,
	updateOptOutStateInConversationList,
	updateArchivedStateInActiveConversation,
	getMessagesByCampaignIdWithMetrics,
	updateActiveConversationTextFieldDirty,
	handleUpdateMessageEventConversation,
	handleReadConversation,
	handleNewConversationInList,
	getSpamScore,
};

import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, ToastMessage, operators, orderTypes } from '@app/constants';

import { fetchAction } from '../types';
import { FilterOptions } from '@app/types/index';
import axios from '@global/axios';
import { importStoryteller } from './types';
import { StorytellerDropdownFilter } from '../constants';
const mainType = 'storyteller';

export const importStorytellersTypes = createFetchTypes(`${mainType}_IMPORT`);

export const verifyStorytellerTypes = createFetchTypes(`${mainType}_VERIFY`);
export const resendStorytellerVerificationTypes = createFetchTypes(
	`${mainType}_RESEND`,
);

export const getStorytellerOptionsTypes = createFetchTypes(
	`${mainType}_GET_OPTIONS`,
);

function getStorytellerOptions(
	searchValue?: string,
	includeAll = true,
): fetchAction {
	const filterOptions = {
		search: {
			fields: ['firstName', 'lastName', 'fullName'],
			value: searchValue || '',
		},
	};
	const filter = { ...filterOptions, ...StorytellerDropdownFilter };
	return {
		type: getStorytellerOptionsTypes,
		callAPI: () =>
			axios.get(`${apiUrl}/storytellers?filter=${JSON.stringify(filter)}`),
		payload: {
			includeAll: includeAll,
		},
	};
}

function importStorytellers(
	data: importStoryteller,
	custom = 'importStorytellers',
): fetchAction {
	return {
		type: importStorytellersTypes,
		callAPI: () => axios.post(`${apiUrl}/storytellers/import`, data),
		payload: {
			custom,
		},
	};
}

function resetImportStorytellers() {
	return {
		type: 'storyteller/RESET_IMPORT_STORYTELLERS',
	};
}

export const bulkResendInvitationsTypes = createFetchTypes(
	`${mainType}_RESEND_BULK_INVITATION`,
);

function bulkResendInvitations({
	data,
	showToast = true,
}: {
	data: { storytellers: string[] };
	showToast?: boolean;
}): fetchAction {
	return {
		type: bulkResendInvitationsTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/storytellers/bulk/invite/resend`, data),
		payload: {
			custom: 'resendBulkInvitation',
			successMessage: showToast && ToastMessage.workspaceInvitationSent,
		},
	};
}

export const exportStorytellersTypes = createFetchTypes(`${mainType}_EXPORT`);

function exportStorytellers(filterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify(filterOptions);
	return {
		type: exportStorytellersTypes,
		callAPI: () =>
			axios({
				url: `${apiUrl}/storytellers/export${
					filterOptions ? `?filter=${filterString}` : ``
				}`,
				method: 'GET',
				responseType: 'blob',
			}),
		payload: {
			custom: 'exportStorytellers',
		},
	};
}

export const getSingleStorytellerTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_STORYTELLER`,
);

function getSingleStoryteller(id: string) {
	return {
		type: getSingleStorytellerTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers/${id}`),
		payload: {
			custom: 'singleStoryteller',
		},
	};
}
export const filterStorytellersTypes = createFetchTypes(`${mainType}_FILTER`);

function filterStorytellers(
	FilterOptions: Partial<FilterOptions>,
): fetchAction {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: filterStorytellersTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/storytellers${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
			),
	};
}

export const getAllWorkspaceStorytellersType = createFetchTypes(
	`${mainType}_GET_ALL`,
);

function getAllWorkspaceStorytellers(): fetchAction {
	const filterOptions = {
		pagination: {
			page: 1,
			pageSize: 1000, //Big page to get all? Most of a workaround
		},
		filter: [
			{
				field: 'role',
				value: ['recorder', 'admin'],
			},
		],
	};

	const filterString = JSON.stringify(filterOptions);
	return {
		type: getAllWorkspaceStorytellersType,
		callAPI: () => axios.get(`${apiUrl}/storytellers?filter=${filterString}`),
	};
}

export const filterStorytellerPendingInvitesTypes = createFetchTypes(
	`${mainType}_FILTER_PENDING_INVITES`,
);

function filterStorytellerPendingInvites(
	search?: string,
	externalFilterOptions?: FilterOptions,
): fetchAction {
	const filterObject = [
		{
			field: 'lastLoginAt',
			operator: operators.exists,
			value: false,
		},
		{
			field: 'role',
			value: 'recorder',
		},
	];

	const paginationObject = {
		page: 1,
		pageSize: 100,
	};

	const orderObject = { field: 'createdAt', type: orderTypes.descending };

	const searchObject = {
		fields: ['firstName', 'lastName'],
		value: search,
	};

	const filterOptions = {
		pagination: paginationObject,
		filter: filterObject,
		order: orderObject,
		search: searchObject,
	};

	const filterString = externalFilterOptions
		? JSON.stringify(externalFilterOptions)
		: JSON.stringify(filterOptions);

	return {
		type: filterStorytellerPendingInvitesTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers?filter=${filterString}`),
	};
}

export const filterAdminsTypes = createFetchTypes(`${mainType}_FILTER_ADMINS`);

function filterAdmins(FilterOptions: FilterOptions): fetchAction {
	const { filter } = FilterOptions;

	if (filter) {
		FilterOptions?.filter?.push({ field: 'role', value: 'admin' });
	} else {
		FilterOptions.filter = [{ field: 'role', value: 'admin' }];
	}

	const filterString = JSON.stringify(FilterOptions);

	return {
		type: filterAdminsTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers?filter=${filterString}`),
		payload: {
			custom: 'admins',
		},
	};
}

export const filterPendingInvitesTypes = createFetchTypes(
	`${mainType}_FILTER_ALL_PENDING_INVITES`,
);

function filterPendingInvites(search?: string): fetchAction {
	const filterObject = [
		{
			field: 'lastLoginAt',
			operator: operators.exists,
			value: false,
		},
	];

	const searchObject = {
		fields: ['firstName', 'lastName'],
		value: search,
	};

	const paginationObject = {
		page: 1,
		pageSize: 100,
	};

	const orderObject = { field: 'updatedAt', type: orderTypes.descending };

	const filterOptions = {
		pagination: paginationObject,
		filter: filterObject,
		order: orderObject,
		search: searchObject,
	};

	const filterString = JSON.stringify(filterOptions);
	return {
		type: filterPendingInvitesTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers?filter=${filterString}`),
	};
}

export const filterAdminPendingInvitesTypes = createFetchTypes(
	`${mainType}_FILTER_ADMINS_PENDING_INVITES`,
);

function filterAdminPendingInvites(search?: string): fetchAction {
	const filterObject = [
		{
			field: 'lastLoginAt',
			operator: operators.exists,
			value: false,
		},
		{
			field: 'role',
			value: 'admin',
		},
	];

	const searchObject = {
		fields: ['firstName', 'lastName'],
		value: search,
	};

	const paginationObject = {
		page: 1,
		pageSize: 100,
	};

	const orderObject = { field: 'createdAt', type: orderTypes.descending };

	const filterOptions = {
		pagination: paginationObject,
		filter: filterObject,
		order: orderObject,
		search: searchObject,
	};

	const filterString = JSON.stringify(filterOptions);
	return {
		type: filterAdminPendingInvitesTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers?filter=${filterString}`),
		payload: {
			custom: 'admins',
		},
	};
}

export const retrieveSavedAdminsTypes = createFetchTypes(
	`${mainType}_RETRIEVE_SAVED_ADMINS`,
);

function retrieveSavedAdmins(admins: string[]) {
	const filter = {
		filter: [
			{
				field: '_id',
				value: admins,
			},
		],
	};
	return {
		type: filterStorytellersTypes,
		callAPI: () =>
			axios.get(`${apiUrl}/storytellers?filter=${JSON.stringify(filter)}`),
		payload: {
			custom: 'retrieveSavedAdmins',
		},
	};
}

export const createStorytellerTypes = createFetchTypes(`${mainType}_CREATE`);

function createStoryteller(
	data: { [key: string]: any },
	custom?: string,
	successMessage?: string,
	errorMessage?: string,
): fetchAction {
	return {
		type: createStorytellerTypes,
		callAPI: () => axios.post(`${apiUrl}/storytellers`, data),
		payload: {
			custom,
			successMessage: successMessage || ToastMessage.storytellerCreated,
			errorMessage: errorMessage || ToastMessage.errorCreatingStoryteller,
		},
	};
}

function createStorytellerV2(
	data: { [key: string]: any },
	custom?: string,
	successMessage?: string,
	errorMessage?: string,
): fetchAction {
	return {
		type: createStorytellerTypes,
		callAPI: () => axios.post(`${apiUrl}/storytellers/v2`, data),
		payload: {
			custom,
			successMessage: successMessage || ToastMessage.storytellerCreated,
			errorMessage: errorMessage || ToastMessage.errorCreatingStoryteller,
		},
	};
}

export const updateStorytellerTypes = createFetchTypes(`${mainType}_UPDATE`);

function updateStoryteller(data: { [key: string]: any }): fetchAction {
	const { id, ...storyteller } = data;
	return {
		type: updateStorytellerTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/storytellers`, {
				id,
				storyteller,
			}),
		payload: {
			custom: 'updateStoryteller',
			successMessage: ToastMessage.storytellerUpdated,
			errorMessage: ToastMessage.errorEditingStoryteller,
		},
	};
}

function verifyStoryteller(id: string): fetchAction {
	return {
		type: verifyStorytellerTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers/${id}/verifySender`),
		payload: {
			custom: 'verification',
		},
	};
}

function resendStorytellerVerification(id: string): fetchAction {
	return {
		type: resendStorytellerVerificationTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers/${id}/resendVerification`),
		payload: {
			custom: 'verification',
		},
	};
}

export const resetStoryTellerType = `${mainType}/RESET`;

export function resetStoryteller() {
	return {
		type: resetStoryTellerType,
	};
}

export const manuallyAddStoryteller = `${mainType}/MANUALLY_ADD_STORYTELLER`;

export function manualyAddStoryteller(storyteller: any) {
	return {
		type: manuallyAddStoryteller,
		payload: storyteller,
	};
}

export const sendSmsAppLinkTypes = createFetchTypes(
	`${mainType}_SEND_SMS_APPLINK`,
);

function sendSmsAppLink(payload: { number: string }): fetchAction {
	return {
		type: sendSmsAppLinkTypes,
		callAPI: () => axios.post(`${apiUrl}/storytellers/smsAppLinks`, payload),
	};
}

export const uploadStorytellerProfilePictureType = createFetchTypes(
	`${mainType}_UPLOAD_PROFILE_PICTURE`,
);

function uploadStorytellerProfilePicture(profilePictureForm: FormData) {
	return {
		type: uploadStorytellerProfilePictureType,
		callAPI: () =>
			axios.post(
				`${apiUrl}/storytellers/uploadProfilePicture`,
				profilePictureForm,
			),
	};
}

export const clearStorytellersType = 'storyteller/CLEAR_STORYTELLERS';

function clearStorytellers() {
	return {
		type: clearStorytellersType,
	};
}

export const bulkSoftDeleteStorytellersType = createFetchTypes(
	`${mainType}/DELETE_STORYTELLERS`,
);

function bulkSoftDeleteStorytellers(storytellerIds: string[]) {
	return {
		type: bulkSoftDeleteStorytellersType,
		callAPI: () =>
			axios.delete(`${apiUrl}/storytellers/soft/bulk`, {
				data: { storytellerIds: storytellerIds },
			}),
		payload: {
			storytellerIds,
			successMessage: ToastMessage.storytellerDeleted,
			errorMessage: ToastMessage.error,
		},
	};
}

export const getNotificationPreferencesType = createFetchTypes(
	`${mainType}/GET_NOTIFICATION_PREFERENCES`,
);

function getNotificationPreferences(storytellerId: string) {
	return {
		type: getNotificationPreferencesType,
		callAPI: () =>
			axios.get(
				`${apiUrl}/storytellers/${storytellerId}/notification/preferences`,
			),
		payload: {
			custom: 'notificationPreferences',
		},
	};
}

export const updateNotificationPreferencesType = createFetchTypes(
	`${mainType}/UPDATE_NOTIFICATION_PREFERENCES`,
);

function updateNotificationPreferences(
	storytellerId: string,
	preferences: {
		[key: string]: {
			[key: string]: {
				email: boolean;
				sms: boolean;
			};
		};
	},
	inboxNotificationSchedule: number[],
	inboxNotificationTeams: string[],
) {
	return {
		type: updateNotificationPreferencesType,
		callAPI: () =>
			axios.put(
				`${apiUrl}/storytellers/${storytellerId}/notification/preferences`,
				{
					preferences,
					inboxNotificationSchedule,
					inboxNotificationTeams,
				},
			),
		payload: {
			custom: 'updateNotificationPreferences',
			successMessage: ToastMessage.notificationPreferencesUpdated,
			errorMessage: ToastMessage.errorUpdatingNotificationPreferences,
		},
	};
}

export {
	getSingleStoryteller,
	filterStorytellers,
	filterStorytellerPendingInvites,
	filterAdmins,
	filterAdminPendingInvites,
	filterPendingInvites,
	createStoryteller,
	createStorytellerV2,
	updateStoryteller,
	importStorytellers,
	verifyStoryteller,
	resendStorytellerVerification,
	exportStorytellers,
	sendSmsAppLink,
	uploadStorytellerProfilePicture,
	getStorytellerOptions,
	clearStorytellers,
	retrieveSavedAdmins,
	bulkSoftDeleteStorytellers,
	bulkResendInvitations,
	getAllWorkspaceStorytellers,
	getNotificationPreferences,
	updateNotificationPreferences,
	resetImportStorytellers,
};
